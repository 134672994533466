<template>
  <div class="modal">
    <div class="box">
      <div class="title">{{ msg.title || '' }}</div>
      <div class="text22" v-html="msg.content" v-if="msg.content"></div>
      <div class="text22 color" v-html="msg.content2" v-if="msg.content2"></div>
      <div v-if="msg.type">
        <div v-if="msg.type == 'excelDate'">
          <strong>发货日期:</strong>&nbsp;&nbsp;
          <input type="date" placeholder="选一天" v-model="value" class="excelDate" />
        </div>
        <div v-if="msg.type == 'years'">
          <strong>时间(年):</strong>&nbsp;&nbsp;
          <input type="number" v-model.trim="value" min="1" class="excelDate" />年
        </div>
        <div v-if="msg.type == 'number'">
          <strong>数量:</strong>&nbsp;&nbsp;
          <input type="number" v-model.trim="value" min="1" class="excelDate" />
        </div>
        <div v-if="msg.type == 'password'" class="eye">
          <form>
            <strong>密码:</strong>&nbsp;&nbsp;
            <input :type="eyes" v-model.trim="value" placeholder="请输入新密码" class="excelDate" />
          </form>
          <i>
            <Hide class="eyes" v-if="eyes == 'password' ? true : false" @click="eyes = 'text'" />
            <View v-else @click="eyes = 'password'" class="eyes" />
          </i>
        </div>
        <div v-if="msg.type == 'textarea'" style="display: flex; align-items: center; justify-content: center">
          <strong>备注:</strong>&nbsp;&nbsp;
          <textarea v-model.trim="value" class="excelDate" style="width: 66%" :placeholder="msg.value"></textarea>
        </div>
        <div v-if="msg.type == 'deviceSn'" style="display: flex; align-items: center; justify-content: center">
          <strong>设备序列号:</strong>&nbsp;&nbsp;
          <input v-model.trim="value" class="excelDate" placeholder="请输入设备序列号" type="text" />
        </div>
        <div v-if="msg.type == 'excel'">
          <!-- <input type="date" placeholder="选择时间范围" v-model="value" class="excelDate" /> -->
          <el-date-picker
            v-model="value"
            type="daterange"
            placeholder="导出日期"
            start-placeholder="Start date"
            range-separator="To"
            end-placeholder="End date"
            @change="timeChange(value)"
          />
        </div>
      </div>

      <div @click="emit('click')" class="dialog-btn">关闭</div>
      <!-- @click="emit('delect', value)" -->
      <div @click="btn" class="dialog-btn btn1" :class="loading ? 'btn3' : ''">
        {{ msg.btn }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { ElNotification, ElDatePicker } from 'element-plus'
import { View, Hide } from '@element-plus/icons-vue'
const emit = defineEmits(['click', 'delect'])
const props = defineProps({
  msg: {
    type: Object,
    required: true,
  },
})
const loading = ref(false)
const value = ref(null)
const eyes = ref('password')
nextTick(() => {
  if (props.msg.type) {
    switch (props.msg.type) {
      case 'number':
        value.value = 1
        break
      case 'years':
        value.value = 1
        break
      case 'textarea':
      default:
        value.value = null
        break
    }
  }
})
const btn = () => {
  if (loading.value) {
    return
  }
  loading.value = true
  setTimeout(() => {
    loading.value = false
  }, 2000)
  if (props.msg.type) {
    if (!value.value) {
      let type = ''
      switch (props.msg.type) {
        case 'excelDate':
          type = '请选择发货日期'
          break
        case 'number':
          type = '请输入数量'
          break
        case 'password':
          type = '请输入密码'
          break
        case 'excel':
          type = '请选择日期'
          break
        case 'years':
          type = '请输入数量'
          break
        case 'deviceSn':
          type = '请输入设备序列号'
          break
        case 'textarea':
          return emit('delect', value.value)
        default:
          break
      }
      ElNotification({
        title: '警告',
        message: type,
        type: 'warning',
        showClose: false,
        duration: 2000,
      })
      return
    }
  }
  emit('delect', value.value)
}
nextTick(() => timeChange([new Date(), new Date()]))
const timeChange = time => {
  if (props.msg.type != 'excel') {
    return
  }
  const start = new Date(time[0])
  const end = new Date(time[1])
  var startDate = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate()
  var endDate = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate()
  value.value = [startDate, endDate]
}
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  z-index: 2004;
}
.box {
  position: fixed;
  top: 0;
  /* right: 0;
    bottom: 0; */
  left: 0;
  overflow: auto;
  box-shadow: 0px 0px 14px 0px rgba(38, 38, 38, 0.5);
  border-radius: 12px;
  width: 500px;
  /* min-height: 200px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #fff;
  text-align: center;
}
.title {
  text-align: left;
  padding: 20px;
}
.text22 {
  margin: 30px 20px;
}
.color {
  color: red;
}
.dialog-btn {
  color: #606266 !important;
  background: #fff !important;
  border: 1px solid #a8abb2 !important;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  width: 64px;
  margin: 20px;
  display: inline-block;
}
.btn1 {
  color: #fff !important;
  background: #2c82f8 !important;
  border: 1px solid #2c82f8 !important;
}
.btn2 {
  color: #fff !important;
  background: #d62f40 !important;
  border: 1px solid #d62f40 !important;
}
.btn3 {
  color: #fff !important;
  background: #4a7ad8 !important;
  border: 1px solid #4a7ad8 !important;
  cursor: wait;
}
.dialog-btn:active {
  opacity: 0.5;
}
.excelDate {
  border: 1px #606266 solid;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 20px 0;
  position: relative;
}
.eye {
  display: flex;
  align-items: center;
  justify-content: center;
}
.eyes {
  width: 1em;
  height: 1em;
  margin-left: 8px;
  cursor: pointer;
}
</style>
