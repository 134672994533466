const Config = {
  sidebarLevel: 3, // 侧边栏层级（无限级）限制, 默认为 3 级，可根据需求自行扩充
  openAutoJumpOut: true, // 是否开启无操作跳出
  defaultRoute: '/about', // 默认打开的路由
  websocketEnable: false, // 默认关闭 websocket
  showSidebarSearch: true, // 默认打开侧边栏搜索
  notLoginRoute: ['login'], // 无需登录即可访问的路由 name,
  useFrontEndErrorMsg: false, // 默认采用后端返回异常
  stagnateTime: 24 * 3600000, // 无操作停滞时间，默认1小时 (60 * 60 * 1000) 现在是30天 30 * 24 * 60 * 60 * 1000
  baseURL: process.env.VUE_APP_BASE_URL, // API接口baseURL，在根目录.env文件查找对应环境变量配置?
  pictureURL: process.env.VUE_APP_PICTURE_URL,
  firmwareURL: process.env.VUE_APP_FIRMWARE_URL,
  audioURL: process.env.VUE_APP_AUDIO_URL,
  apiURL: process.env.VUE_APP_API_URL,
  wsURL: process.env.VUE_APP_WS_URL,
  jzyURL: process.env.VUE_APP_JZY_URL,
}

export default Config
