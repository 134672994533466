<template>
  <div class="modal">
    <div class="box">
      <div class="title">{{ msg.title || '' }}</div>
      <div v-html="msg.content" v-if="msg.content" class="text22"></div>
      <div v-html="msg.content2" v-if="msg.content2" class="text22 color"></div>

      <div @click="emit('click')" class="dialog-btn">关闭</div>
      <div v-if="msg.btn" @click="submit" class="dialog-btn btn1" :class="loading ? 'btn3' : ''">
        {{ msg.btn }}
      </div>
      <div v-else @click="submit" class="dialog-btn btn2" :class="loading ? 'btn4' : ''">移除</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const emit = defineEmits(['click', 'delect'])
const props = defineProps({
  msg: {
    type: Object,
    required: true,
  },
})

const loading = ref(false)
const submit = () => {
  if (loading.value) {
    return
  }
  loading.value = true
  emit('delect')
  setTimeout(() => {
    loading.value = false
  }, 2000)
}
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  z-index: 2004;
}
.box {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  box-shadow: 0px 0px 14px 0px rgba(38, 38, 38, 0.5);
  border-radius: 12px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #fff;
  text-align: center;
}
.title {
  text-align: left;
  padding: 20px;
}
.text22 {
  margin: 30px 20px;
}
.color {
  color: red;
}
.dialog-btn {
  color: #606266 !important;
  background: #fff !important;
  border: 1px solid #a8abb2 !important;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  width: 64px;
  margin: 20px;
  display: inline-block;
}
.btn1 {
  color: #fff !important;
  background: #2c82f8 !important;
  border: 1px solid #2c82f8 !important;
}
.btn2 {
  color: #fff !important;
  background: #d62f40 !important;
  border: 1px solid #d62f40 !important;
}
.btn3 {
  color: #fff !important;
  background: #4a7ad8 !important;
  border: 1px solid #4a7ad8 !important;
  cursor: wait;
}

.btn4 {
  color: #fff !important;
  background: #f4516c !important;
  border: 1px solid #f4516c !important;
  cursor: wait;
}

.dialog-btn:active {
  opacity: 0.5;
}
</style>
